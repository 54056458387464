<template>
  <div class="create-content-main">
    <div
      class="background-content-admin create-form"
      style="padding-top: 12px !important"
    >
      <div class="create-slag-form">
        <div class="row ml-0 pt-2">
          <h2
            class="col col-auto font-title font-weight-bold cpx-4 my-auto header-font"
            style="margin-right: 10px; bottom: 5px"
            v-if="!this.$route.params.id"
          >
            著者新規登録
          </h2>
          <h2
            class="col col-auto font-title font-weight-bold mx-3 my-auto header-font"
            style="margin-right: 10px; bottom: 5px"
            v-else
          >
            編集
          </h2>
          <div class="col col-auto">
            <div class="mx-3" style="display: flex">
              <div v-for="(item, index) in listSetting" :key="index">
                <label
                  class="mr-3 position-relative py-2"
                  :class="
                    typeSetting === item.value
                      ? 'btn-tab-sale-active'
                      : 'btn-tab-sale-inactive'
                  "
                  @click="typeSetting = item.value"
                  style="font-weight: normal !important; font-size: 17px"
                >
                  {{ item.text }}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="cpx-4 cpy-2">
        <span
          class="col col-auto font-title font-weight-bold cpx-4 my-auto header-font"
          v-if="$route.params.id"
          >編集</span
        >
        <span
          class="col col-auto font-title font-weight-bold cpx-4 my-auto header-font"
          v-else
          >管理者・著者アカウント新規登録</span
        >
      </div>
      <div class="col col-auto">
        <div class="mx-3">
          <label
            v-for="(item, index) in listSetting"
            :key="index"
            class="mr-3 position-relative py-2"
            :class="
              typeSetting === item.value
                ? 'btn-tab-sale-active'
                : 'btn-tab-sale-inactive'
            "
            @click="typeSetting = item.value"
            style="font-weight: normal !important; font-size: 17px"
          >
            {{ item.text }}
          </label>
        </div>
      </div> -->
      <form
        v-on:submit.prevent="CreateNewContent()"
        id="create-content"
        style="background-color: white"
        autocomplete="off"
      >
        <div v-if="typeSetting === 1">
          <div class="row mx-0">
            <!-- <div class="mb-3 row-input col-sm-12 col-lg-12 px-0">
              <label class="col-sm-12 font-weight-bold cp-label custom-text"
                ><span>NO</span></label
              >
              <div class="cp-4">
                <b-form-input
                  v-model="NO"
                  disabled
                  required
                  class="col-lg-3 col-sm-12"
                ></b-form-input>
              </div>
            </div> -->
          </div>
          <div class="row mx-0">
            <div class="mb-3 row-input col-sm-12 col-lg-12 px-0">
              <label class="col-sm-12 font-weight-bold cp-label custom-text"
                ><span>管理者名</span
                ><span class="textMust">（必須）</span></label
              >
              <div class="cp-4">
                <input
                  class="form-input col-lg-3 col-sm-12"
                  type="text"
                  v-model="name_admin"
                />
              </div>
            </div>
          </div>
          <div class="row mx-0">
            <div class="mb-3 row-input col-sm-12 col-lg-12 px-0">
              <label class="col-sm-12 font-weight-bold cp-label custom-text"
                ><span>管理者ID</span></label
              >
              <div class="cp-4">
                <input
                  class="form-input col-lg-3 col-sm-12"
                  disabled="disabled"
                  v-model="id_admin"
                />
              </div>
            </div>
          </div>
          <div class="row mx-0">
            <div class="mb-3 row-input col-sm-12 col-lg-12 px-0">
              <label class="col-sm-12 font-weight-bold cp-label custom-text"
                ><span>メールアドレス</span
                ><span class="textMust">（必須）</span></label
              >
              <div class="cp-4">
                <input
                  class="form-input col-lg-3 col-sm-12"
                  type="text"
                  v-model="email"
                />
              </div>
            </div>
          </div>
          <!-- <div class="row mx-0">
          <div class="col-lg-2 col-sm-12 px-0">
            <label
              class="col-sm-12 font-weight-bold cp-label"
              style="font-weight: 700 !important; font-size: 17px"
              >編集者ランク</label
            >
            <b-form-select
              v-model="rank_editor"
              tag-placeholder="Add this as new category"
              :options="optionRankEditor"
              class="col-sm-12"
            ></b-form-select>
          </div>
        </div> -->
          <!-- <div class="custom-border"></div> -->
        </div>
        <div v-if="typeSetting === 2">
          <div class="row mx-0">
            <div class="mb-3 row-input col-sm-12 col-lg-12 px-0">
              <label class="col-sm-12 font-weight-bold cp-label custom-text"
                ><span>著者利用</span></label
              >
              <div class="cp-4">
                <b-form-select
                  v-model="author_use"
                  tag-placeholder="Add this as new category"
                  :options="optionAuthorUse"
                  class="w-100 col-lg-3 col-sm-12"
                  :disabled="isCdeaAdmin === false"
                ></b-form-select>
              </div>
            </div>
          </div>
          <div class="row mx-0">
            <div class="mb-3 row-input col-sm-12 col-lg-12 px-0">
              <label class="col-sm-12 font-weight-bold cp-label custom-text"
                ><span>著者ID</span></label
              >
              <div class="cp-4">
                <input
                  class="form-input col-lg-3 col-sm-12"
                  disabled="disabled"
                  v-model="id_author"
                />
              </div>
            </div>
          </div>
          <!-- <div v-if="author_use === 1"> -->
          <div class="row mx-0">
            <div class="mb-3 row-input col-sm-12 col-lg-12 px-0">
              <label
                class="col-sm-12 font-weight-bold cp-label custom-text"
                style="background-color: #d5d5d5"
                ><span>姓</span><span class="textMust">（必須）</span></label
              >
              <div class="cp-4">
                <input
                  class="form-input col-lg-3 col-sm-12"
                  v-model="last_name"
                />
              </div>
            </div>
          </div>
          <div class="row mx-0">
            <div class="mb-3 row-input col-sm-12 col-lg-12 px-0">
              <label class="col-sm-12 font-weight-bold cp-label custom-text"
                ><span>名</span><span class="textMust">（必須）</span></label
              >
              <div class="cp-4">
                <input
                  class="form-input col-lg-3 col-sm-12"
                  v-model="first_name"
                />
              </div>
            </div>
          </div>
          <div class="row mx-0">
            <div class="mb-3 row-input col-sm-12 col-lg-12 px-0">
              <label class="col-sm-12 font-weight-bold cp-label custom-text"
                ><span>セミタイトル</span></label
              >
              <div class="cp-4">
                <input
                  class="form-input col-lg-3 col-sm-12"
                  v-model="semi_title"
                />
              </div>
            </div>
          </div>
          <div class="row mx-0">
            <div class="mb-3 row-input col-sm-12 col-lg-12 px-0">
              <label class="col-sm-12 font-weight-bold cp-label custom-text"
                ><span>著者プロフィール</span></label
              >
              <div class="cp-4">
                <textarea
                  class="form-control"
                  v-model="author_profile"
                  rows="6"
                ></textarea>
              </div>
            </div>
          </div>
          <!-- <div class="row mb-3 row-input">
            <label
              class="col-sm-12 font-weight-bold cp-label"
              style="font-weight: 700 !important; font-size: 17px"
              >著者プロフィール</label
            >
            <textarea
              class="form-control"
              v-model="author_profile"
              rows="6"
            ></textarea>
          </div> -->
          <div class="row mb-3 mx-0 row-input">
            <label class="col-sm-12 font-weight-bold cp-label custom-text"
              ><span>著者画像</span></label
            >
            <div class="col-sm-12 row mb-3 img-custome ml-1">
              <div class="col-sm-12">
                <!-- <label for="fileInput" class="btn label-input-file btn-success"
                  >アップロード</label
                > -->
                <label
                  for="fileInput"
                  class="btn label-input-file btn-color"
                  style="color: white; border-radius: 30px"
                  >アップロード</label
                >
                <CButton
                  v-if="avatar || imagePre"
                  class="mx-1 mb-2"
                  color="success"
                  style="
                    background-color: #66615b;
                    border-radius: 30px;
                    border-color: #66615b;
                  "
                  square
                  v-on:click="deleteImage()"
                >
                  写真削除
                </CButton>
                <div class="row">
                  <div class="col-lg-4">
                    <input
                      id="fileInput"
                      class="d-none"
                      @change="onChangeimage($event)"
                      type="file"
                    />
                    <div
                      v-if="imagePre"
                      class="w-100 mt-lg-3"
                      style="
                        max-width: 300px;
                        max-height: 300px;
                        display: table-cell;
                      "
                    >
                      <img
                        v-if="imagePre"
                        :src="imagePre"
                        class="preview-img-author"
                      />
                    </div>
                    <p v-if="checkImg">
                      <img class="preview-img" :src="imagePre" alt="" />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mx-0">
            <div class="mb-3 row-input col-sm-12 col-lg-12 px-0">
              <label class="col-sm-12 font-weight-bold cp-label custom-text"
                ><span>販売元の名称</span></label
              >
              <div class="cp-4">
                <input
                  class="form-input col-lg-3 col-sm-12"
                  v-model="distributor_name"
                />
              </div>
            </div>
          </div>
          <div class="row row-input">
            <label class="col-sm-12 font-weight-bold cp-label">
              登録番号
            </label>
            <div class="cp-4 d-flex align-items-center" style="width: 400px">
              <input
                class="form-input m-0"
                type="text"
                v-model="number_resgiter"
                style="margin: 0"
              />&nbsp;&nbsp;
            </div>
          </div>
        </div>
        <div v-if="typeSetting === 3">
          <div class="row row-input">
            <label class="col-sm-12 font-weight-bold cp-label cp-label"
              ><b-icon
                icon="caret-down-fill"
                class="my-auto"
              />著者一覧ページ表示条件
            </label>
            <div style="margin: 20px 0px; padding: 0px 40px">
              <div class="btn-toggle-active">
                <input
                  v-model="condition_display"
                  class="m-0"
                  type="radio"
                  name="radio-type-show"
                  value="1"
                  id="radio-type-show-1"
                />
                <label
                  for="radio-type-show-1"
                  :class="
                    condition_display == 1
                      ? 'btn-share-freesize-active py-2 px-4'
                      : 'btn-share-freesize py-2 px-4'
                  "
                  style="width: 200px"
                  >コンテンツ所持設定</label
                >
              </div>
              <div class="btn-toggle-active">
                <input
                  v-model="condition_display"
                  class="m-0"
                  type="radio"
                  name="radio-type-show"
                  value="2"
                  id="radio-type-show-2"
                />
                <label
                  for="radio-type-show-2"
                  :class="
                    condition_display == 2
                      ? 'btn-share-freesize-active py-2 px-4'
                      : 'btn-share-freesize py-2 px-4'
                  "
                  style="width: 200px"
                  >スラグ所持設定</label
                >
              </div>
              <div class="btn-toggle-active">
                <input
                  v-model="condition_display"
                  class="m-0"
                  type="radio"
                  name="radio-type-show"
                  id="radio-type-show-3"
                  :value="3"
                />
                <label
                  for="radio-type-show-3"
                  :class="
                    condition_display == 3
                      ? 'btn-share-freesize-active py-2 px-4'
                      : 'btn-share-freesize py-2 px-4'
                  "
                  style="min-width: 200px"
                  >全員に表示（オススメしません）</label
                >
              </div>
              <div class="btn-toggle-active">
                <input
                  v-model="condition_display"
                  class="m-0"
                  type="radio"
                  name="radio-type-show"
                  id="radio-type-show-4"
                  :value="4"
                />
                <label
                  for="radio-type-show-4"
                  :class="
                    condition_display == 4
                      ? 'btn-share-freesize-active py-2 px-4'
                      : 'btn-share-freesize py-2 px-4'
                  "
                  style="width: 200px"
                  >全員非表示にする</label
                >
              </div>
            </div>
          </div>
          <div v-if="condition_display == constant_display['SET_CONTENT']">
            <div class="row row-input">
              <label class="col-sm-12 font-weight-bold cp-label">
                基本設定
              </label>
              <div
                class="d-flex justify-content-between align-items-center add-slag"
                style="margin: 20px 40px; padding: 0; width: 100%"
              >
                <div class="custom-select-2 w-100 custome-multi-slag">
                  <multiselect
                    v-model="content_id_1"
                    tag-placeholder="コンテンツを選択してください"
                    placeholder="コンテンツを選択してください"
                    label="title"
                    track-by="title"
                    :options="listContentOptions"
                    :multiple="true"
                    :max-height="200"
                    select-label="入力して選択する"
                    selected-label="選択された"
                    deselect-label="入力して削除する"
                  >
                    <template slot="noOptions"> リストは空です </template>
                  </multiselect>
                </div>
              </div>
            </div>
            <div class="row row-input">
              <label class="col-sm-12 font-weight-bold cp-label"
                >and1（基本設定とand1両方のコンテンツを持つユーザーだけに表示）
              </label>
              <div
                class="d-flex justify-content-between align-items-center add-slag"
                style="margin: 20px 40px; padding: 0; width: 100%"
              >
                <div class="custom-select-2 w-100 custome-multi-slag">
                  <multiselect
                    v-model="content_id_2"
                    :disabled="content_id_1.length > 0 ? false : true"
                    tag-placeholder="コンテンツを選択してください"
                    placeholder="コンテンツを選択してください"
                    label="title"
                    track-by="title"
                    :options="listContentOptions"
                    :multiple="true"
                    :max-height="200"
                    select-label="入力して選択する"
                    selected-label="選択された"
                    deselect-label="入力して削除する"
                  >
                    <template slot="noOptions"> リストは空です </template>
                  </multiselect>
                </div>
              </div>
            </div>
            <div class="row row-input">
              <label class="col-sm-12 font-weight-bold cp-label"
                >and2（基本設定＋and1＋and2をコンテンツを持つユーザーだけに表示）
              </label>
              <div
                class="d-flex justify-content-between align-items-center add-slag"
                style="margin: 20px 40px; padding: 0; width: 100%"
              >
                <div class="custom-select-2 w-100 custome-multi-slag">
                  <multiselect
                    v-model="content_id_3"
                    :disabled="
                      content_id_1.length > 0 && content_id_2.length > 0
                        ? false
                        : true
                    "
                    tag-placeholder="コンテンツを選択してください"
                    placeholder="コンテンツを選択してください"
                    label="title"
                    track-by="title"
                    :options="listContentOptions"
                    :multiple="true"
                    :max-height="200"
                    select-label="入力して選択する"
                    selected-label="選択された"
                    deselect-label="入力して削除する"
                  >
                    <template slot="noOptions"> リストは空です </template>
                  </multiselect>
                </div>
              </div>
            </div>
            <div class="row row-input">
              <label class="col-sm-12 font-weight-bold cp-label"
                >表示条件を満たしていない場合のリダイレクト先URL</label
              >
              <div class="cp-4 col-12">
                <input
                  class="form-input m-0"
                  type="text"
                  v-model="url_redirect"
                />
              </div>
            </div>
            <div class="row row-input">
              <label class="col-sm-12 font-weight-bold cp-label"
                >非表示（以下のコンテンツを持つユーザーは非表示）
              </label>
              <div
                class="d-flex justify-content-between align-items-center add-slag"
                style="margin: 20px 40px; padding: 0; width: 100%"
              >
                <div class="custom-select-2 w-100 custome-multi-slag">
                  <multiselect
                    v-model="content_id_4"
                    tag-placeholder="コンテンツを選択してください"
                    placeholder="コンテンツを選択してください"
                    label="title"
                    track-by="title"
                    :options="listContentOptions"
                    :multiple="true"
                    :max-height="200"
                    select-label="入力して選択する"
                    selected-label="選択された"
                    deselect-label="入力して削除する"
                  >
                    <template slot="noOptions"> リストは空です </template>
                  </multiselect>
                </div>
              </div>
            </div>
            <div class="row row-input">
              <label class="col-sm-12 font-weight-bold cp-label"
                >非表示条件に該当する場合のリダイレクト先URL</label
              >
              <div class="cp-4 col-12">
                <input
                  class="form-input m-0"
                  type="text"
                  v-model="url_cancel"
                />
              </div>
            </div>
          </div>
          <div v-if="condition_display == constant_display['SET_SLAG']">
            <div class="row row-input">
              <label class="col-sm-12 font-weight-bold cp-label">
                基本設定
              </label>
              <div
                class="d-flex justify-content-between align-items-center add-slag"
                style="margin: 20px 40px; padding: 0; width: 100%"
              >
                <div class="custom-select-2 w-100 custome-multi-slag">
                  <multiselect
                    v-model="slag_id_1"
                    tag-placeholder="スラグを選択してください"
                    placeholder="スラグを選択してください"
                    label="slag_name"
                    track-by="slag_name"
                    :options="listSlagOptions"
                    :multiple="true"
                    :max-height="200"
                    select-label="入力して選択する"
                    selected-label="選択された"
                    deselect-label="入力して削除する"
                  >
                    <template slot="noOptions"> リストは空です </template>
                  </multiselect>
                </div>
              </div>
            </div>
            <div class="row row-input">
              <label class="col-sm-12 font-weight-bold cp-label"
                >and1（基本設定とand1両方のスラグを持つユーザーだけに表示）
              </label>
              <div
                class="d-flex justify-content-between align-items-center add-slag"
                style="margin: 20px 40px; padding: 0; width: 100%"
              >
                <div class="custom-select-2 w-100 custome-multi-slag">
                  <multiselect
                    v-model="slag_id_2"
                    :disabled="slag_id_1.length > 0 ? false : true"
                    tag-placeholder="スラグを選択してください"
                    placeholder="スラグを選択してください"
                    label="slag_name"
                    track-by="slag_name"
                    :options="listSlagOptions"
                    :multiple="true"
                    :max-height="200"
                    select-label="入力して選択する"
                    selected-label="選択された"
                    deselect-label="入力して削除する"
                  >
                    <template slot="noOptions"> リストは空です </template>
                  </multiselect>
                </div>
              </div>
            </div>
            <div class="row row-input">
              <label class="col-sm-12 font-weight-bold cp-label"
                >and2（基本設定＋and1＋and2をスラグを持つユーザーだけに表示
              </label>
              <div
                class="d-flex justify-content-between align-items-center add-slag"
                style="margin: 20px 40px; padding: 0; width: 100%"
              >
                <div class="custom-select-2 w-100 custome-multi-slag">
                  <multiselect
                    v-model="slag_id_3"
                    :disabled="
                      slag_id_1.length > 0 && slag_id_2.length > 0
                        ? false
                        : true
                    "
                    tag-placeholder="スラグを選択してください"
                    placeholder="スラグを選択してください"
                    label="slag_name"
                    track-by="slag_name"
                    :options="listSlagOptions"
                    :multiple="true"
                    :max-height="200"
                    select-label="入力して選択する"
                    selected-label="選択された"
                    deselect-label="入力して削除する"
                  >
                    <template slot="noOptions"> リストは空です </template>
                  </multiselect>
                </div>
              </div>
            </div>
            <div class="row row-input">
              <label class="col-sm-12 font-weight-bold cp-label"
                >表示条件を満たしていない場合のリダイレクト先URL</label
              >
              <div class="cp-4 col-12">
                <input
                  class="form-input m-0"
                  type="text"
                  v-model="url_redirect"
                />
              </div>
            </div>
            <div class="row row-input">
              <label class="col-sm-12 font-weight-bold cp-label"
                >非表示（以下のスラグを持つユーザーは非表示）
              </label>
              <div
                class="d-flex justify-content-between align-items-center add-slag"
                style="margin: 20px 40px; padding: 0; width: 100%"
              >
                <div class="custom-select-2 w-100 custome-multi-slag">
                  <multiselect
                    v-model="slag_id_4"
                    tag-placeholder="スラグを選択してください"
                    placeholder="スラグを選択してください"
                    label="slag_name"
                    track-by="slag_name"
                    :options="listSlagOptions"
                    :multiple="true"
                    :max-height="200"
                    select-label="入力して選択する"
                    selected-label="選択された"
                    deselect-label="入力して削除する"
                  >
                    <template slot="noOptions"> リストは空です </template>
                  </multiselect>
                </div>
              </div>
            </div>
            <div class="row row-input">
              <label class="col-sm-12 font-weight-bold cp-label"
                >非表示条件に該当する場合のリダイレクト先URL</label
              >
              <div class="cp-4 col-12">
                <input
                  class="form-input m-0"
                  type="text"
                  v-model="url_cancel"
                />
              </div>
            </div>
          </div>
          <div
            v-if="
              condition_display != constant_display['SET_CONTENT'] &&
              condition_display != constant_display['SET_SLAG']
            "
          >
            <div class="row row-input">
              <label class="col-sm-12 font-weight-bold cp-label"
                >表示条件を満たしていない場合のリダイレクト先URL</label
              >
              <div class="cp-4 col-12">
                <input
                  class="form-input m-0"
                  type="text"
                  v-model="url_redirect"
                />
              </div>
            </div>
            <div class="row row-input">
              <label class="col-sm-12 font-weight-bold cp-label"
                >非表示条件に該当する場合のリダイレクト先URL</label
              >
              <div class="col-12 cp-4">
                <input
                  class="form-input m-0"
                  type="text"
                  v-model="url_cancel"
                />
              </div>
            </div>
          </div>
          <div class="row mb-3 mx-0 row-input">
            <div class="col-sm-12 px-0">
              <label class="col-sm-12 font-weight-bold cp-label custom-text"
                ><span>プライバシーポリシー</span></label
              >
              <!-- <label
                class="col-sm-12 font-weight-bold cp-label"
                style="font-weight: 700 !important; font-size: 17px"
                >プライバシーポリシー</label
              > -->
              <vue-editor
                useCustomImageHandler
                @image-added="handleImageAdded"
                @image-removed="handleImageRemove"
                v-model="privacy_policy"
                class="w-100 p-3"
              ></vue-editor>
            </div>
            <div class="col-sm-12 px-0 mt-4">
              <!-- <label
                class="col-sm-12 font-weight-bold cp-label"
                style="font-weight: 700 !important; font-size: 17px"
                >個人情報の管理・取扱、販売サイト利用規約について</label
              > -->
              <label class="col-sm-12 font-weight-bold cp-label custom-text"
                ><span
                  >個人情報の管理・取扱、販売サイト利用規約について</span
                ></label
              >
              <vue-editor
                useCustomImageHandler
                @image-added="handleImageAdded"
                @image-removed="handleImageRemove"
                v-model="managementofsalessite"
                class="w-100 p-3"
              ></vue-editor>
            </div>
          </div>
          <div class="row mb-3 row-input mx-0">
            <label
              class="col-sm-12 font-weight-bold custom-text cp-label"
              style="font-weight: 700 !important"
              >特定商取引法URL</label
            >
            <div class="w-100 cp-4">
              <input
                class="form-input col-sm-12"
                type="text"
                v-model="specified_URL"
              />
              <input
                class="form-input col-sm-12"
                type="text"
                v-model="id"
                hidden
              />
            </div>
          </div>
          <div class="row mx-0 row-input">
            <label class="col-sm-12 font-weight-bold custom-text cp-label">
              Stripe/paypal設定
            </label>
            <div class="col-sm-2">
              <label variant="secondary" class="font-weight-bold cp-4">
                Paypal
              </label>
            </div>
            <div class="col-sm-10 ml-auto cp-4 pl-0">
              <label>PayPal Client ID</label>
              <input class="form-input w-100" type="text" v-model="paypal_id" />
            </div>
          </div>
          <div class="row mb-3 mx-0 row-input">
            <div class="col-sm-2">
              <span variant="secondary"></span>
            </div>
            <div class="col-sm-10 ml-auto cp-4 pl-0">
              <label>PayPal Client Secret</label>
              <input
                class="form-input w-100"
                type="text"
                v-model="paypal_secret"
              />
            </div>
          </div>
          <div class="row mb-3 mx-0 row-input">
            <div class="col-sm-2">
              <label variant="secondary" class="font-weight-bold cp-4">
                Stripe
              </label>
            </div>
            <div class="col-sm-10 ml-auto cp-4 pl-0">
              <label>Stripe Key Public</label>
              <input class="form-input w-100" type="text" v-model="stripe_id" />
            </div>
          </div>
          <div class="row mb-3 mx-0 row-input">
            <div class="col-sm-2">
              <span variant="secondary"></span>
            </div>
            <div class="col-sm-10 ml-auto cp-4 pl-0">
              <label>Stripe Key Secret</label>
              <input
                class="form-input w-100"
                type="text"
                v-model="stripe_secret"
              />
            </div>
          </div>
        </div>
        <div v-if="typeSetting === 4">
          <div class="row mx-0 row-input">
            <div class="col-sm-12 px-0">
              <label
                variant="secondary"
                class="font-weight custom-text cp-label"
              >
                著者に基づくメール設定
              </label>
            </div>
          </div>
          <!-- <div class="row mb-3 mx-0 row-input">
            <div class="col-sm-2 cp-4 pr-0">
              <label variant="secondary">自動返信メール設定</label>
            </div>
          </div> -->
          <div class="row align-items-center justify-content-center row-input">
            <div class="col-auto">
              <div class="title-btn mx-3 mt-0 col-lg-12">
                <label
                  class="mr-3 text-nowrap position-relative"
                  :class="
                    index === isChecked
                      ? 'btn-tab-mail-active py-2 px-4'
                      : 'btn-tab-mail-inactive py-2 px-4'
                  "
                  v-on:click="ClickContentChecked(index, $event)"
                  v-for="(item, index) in listLabelSettingMail"
                  :key="index"
                  @click="setValueLabel(item.value)"
                >
                  {{ item.text }}
                </label>
              </div>
            </div>
          </div>
          <input
            type="file"
            hidden
            ref="selectFile"
            id="idSelectFile"
            accept=".pdf"
            @change="changePDF($event)"
          />
          <div class="row mb-3 mx-0 row-input">
            <div class="col-sm-12 custom-text cp-4" style="align-items: unset">
              <label v-if="valueTypeMail === 0" variant="secondary" class="my-0"
                >販売者に届く購入通知
              </label>
              <label
                v-else-if="valueTypeMail === 1"
                variant="secondary"
                class="my-0"
                >ユーザーに届く購入通知
              </label>
              <label
                v-else-if="valueTypeMail === 2"
                variant="secondary"
                class="my-0"
                >お客様に送るカード決済後のメール
              </label>
              <label
                v-else-if="valueTypeMail === 3"
                variant="secondary"
                class="my-0"
                >お客様に送る銀行振込申し込み後のメール
              </label>
              <label
                v-else-if="valueTypeMail === 4"
                variant="secondary"
                class="my-0"
                >振込確認後に送るメール
              </label>
              <label
                v-else-if="valueTypeMail === 5"
                variant="secondary"
                class="my-0"
                >お客様に送る定期購読申し込み後のメール
              </label>
              <!-- <label v-else-if="valueTypeMail === 6" variant="secondary"
                >お客様に送るアンケート回答後メール
              </label>
              <label
                v-else-if="valueTypeMail === 7"
                variant="secondary"
                class="font-weight-bold"
                >お客様に送る投票後メール
              </label>
              <label
                v-else-if="valueTypeMail === 8"
                variant="secondary"
                class="font-weight-bold"
                >販売者に届く投票後通知
              </label>
              <label
                v-else-if="valueTypeMail === 9"
                variant="secondary"
                class="font-weight-bold"
                >お客様に送る申込（0円決済）後メール
              </label> -->
              <label
                v-else-if="valueTypeMail === Constants.AUTHOR_EMAIL_TYPE.external_payment_approval_check_to_user"
                variant="secondary"
                class="my-0"
                >お客様に送る外部決済手動承認受付のメール
              </label>
              <label
                v-else-if="valueTypeMail === Constants.AUTHOR_EMAIL_TYPE.external_payment_approved_to_user"
                variant="secondary"
                class="my-0"
                >お客様に送る外部決済確認完了お知らせのメール
              </label>
            </div>
          </div>
          <div class="row mb-3 mx-0 row-input">
            <div class="col-sm-12 w-100 cp-4" v-if="valueTypeMail === 0">
              <div
                class="row mx-0 align-items-center justify-content-center row-input mb-3"
              >
                <label class="col-2">メールの送信者名</label>
                <input
                  class="form-input w-100 mb-4 col-10"
                  type="text"
                  v-model="email_name"
                />
              </div>
              <div
                class="row mx-0 align-items-center justify-content-center row-input mb-3"
              >
                <label class="col-2">件名</label>
                <input
                  class="form-input w-100 mb-4 col-10"
                  type="text"
                  v-model="mail_subject"
                />
              </div>
              <div class="row mx-0 justify-content-center row-input mb-3">
                <label class="col-2">本文</label>
                <textarea
                  class="form-control col-10"
                  v-model="text"
                  rows="6"
                ></textarea>
              </div>
            </div>
            <div class="col-sm-12 w-100 cp-4" v-if="valueTypeMail === 1">
              <div
                class="row mx-0 align-items-center justify-content-center row-input mb-3"
              >
                <label class="col-2">メールの送信者名</label>
                <input
                  class="form-input w-100 mb-4 col-10"
                  type="text"
                  v-model="email_name1"
                />
              </div>
              <div
                class="row mx-0 align-items-center justify-content-center row-input mb-3"
              >
                <label class="col-2">件名</label>
                <input
                  class="form-input w-100 mb-4 col-10"
                  type="text"
                  v-model="mail_subject1"
                />
              </div>
              <div class="row mx-0 justify-content-center row-input mb-3">
                <label class="col-2">本文</label>
                <textarea
                  class="form-control col-10"
                  v-model="text1"
                  rows="6"
                ></textarea>
              </div>
            </div>
            <div class="col-sm-12 w-100 cp-4" v-if="valueTypeMail === 2">
              <div
                class="row mx-0 align-items-center justify-content-center row-input mb-3"
              >
                <label class="col-2">メールの送信者名</label>
                <input
                  class="form-input w-100 mb-4 col-10"
                  type="text"
                  v-model="email_name2"
                />
              </div>
              <div
                class="row mx-0 align-items-center justify-content-center row-input mb-3"
              >
                <label class="col-2">件名</label>
                <input
                  class="form-input w-100 mb-4 col-10"
                  type="text"
                  v-model="mail_subject2"
                />
              </div>
              <div class="row mx-0 justify-content-center row-input mb-3">
                <label class="col-2">本文</label>
                <textarea
                  class="form-control col-10"
                  v-model="text2"
                  rows="6"
                ></textarea>
              </div>
            </div>
            <div class="col-sm-12 w-100 cp-4" v-if="valueTypeMail === 3">
              <div
                class="row mx-0 align-items-center justify-content-center row-input mb-3"
              >
                <label class="col-2">メールの送信者名</label>
                <input
                  class="form-input w-100 mb-4 col-10"
                  type="text"
                  v-model="email_name3"
                />
              </div>
              <div
                class="row mx-0 align-items-center justify-content-center row-input mb-3"
              >
                <label class="col-2">件名</label>
                <input
                  class="form-input w-100 mb-4 col-10"
                  type="text"
                  v-model="mail_subject3"
                />
              </div>
              <div class="row justify-content-center row-input mb-3">
                <label class="col-2">本文</label>
                <textarea
                  class="form-control col-10"
                  v-model="text3"
                  rows="6"
                ></textarea>
              </div>
            </div>
            <div class="col-sm-12 w-100 cp-4" v-if="valueTypeMail === 4">
              <div
                class="row mx-0 align-items-center justify-content-center row-input mb-3"
              >
                <label class="col-2">メールの送信者名</label>
                <input
                  class="form-input w-100 mb-4 col-10"
                  type="text"
                  v-model="email_name4"
                />
              </div>
              <div
                class="row mx-0 align-items-center justify-content-center row-input mb-3"
              >
                <label class="col-2">件名</label>
                <input
                  class="form-input w-100 mb-4 col-10"
                  type="text"
                  v-model="mail_subject4"
                />
              </div>
              <div class="row mx-0 justify-content-center row-input mb-3">
                <label class="col-2">本文</label>
                <textarea
                  class="form-control col-10"
                  v-model="text4"
                  rows="6"
                ></textarea>
              </div>
            </div>
            <div class="col-sm-12 w-100 cp-4" v-if="valueTypeMail === 5">
              <div
                class="row mx-0 align-items-center justify-content-center row-input mb-3"
              >
                <label class="col-2">メールの送信者名</label>
                <input
                  class="form-input w-100 mb-4 col-10"
                  type="text"
                  v-model="email_name5"
                />
              </div>
              <div
                class="row mx-0 align-items-center justify-content-center row-input mb-3"
              >
                <label class="col-2">件名</label>
                <input
                  class="form-input w-100 mb-4 col-10"
                  type="text"
                  v-model="mail_subject5"
                />
              </div>
              <div class="row mx-0 justify-content-center row-input mb-3">
                <label class="col-2">本文</label>
                <textarea
                  class="form-control col-10"
                  v-model="text5"
                  rows="6"
                ></textarea>
              </div>
            </div>
            <!-- <div class="col-sm-10 w-100" v-if="valueTypeMail === 6">
              <label>メールの送信者名</label>
              <input
                class="form-input w-100 mb-4"
                type="text"
                v-model="email_name6"
              />
              <label>件名</label>
              <input
                class="form-input w-100 mb-4"
                type="text"
                v-model="mail_subject6"
              />
              <label>本文</label>
              <textarea
                class="form-control"
                v-model="text6"
                rows="6"
              ></textarea>
            </div>
            <div class="col-sm-10 w-100" v-if="valueTypeMail === 7">
              <label>メールの送信者名</label>
              <input
                class="form-input w-100 mb-4"
                type="text"
                v-model="email_name7"
              />
              <label>件名</label>
              <input
                class="form-input w-100 mb-4"
                type="text"
                v-model="mail_subject7"
              />
              <label>本文</label>
              <textarea
                class="form-control"
                v-model="text7"
                rows="6"
              ></textarea>
            </div>
            <div class="col-sm-10 w-100" v-if="valueTypeMail === 8">
              <label>メールの送信者名</label>
              <input
                class="form-input w-100 mb-4"
                type="text"
                v-model="email_name8"
              />
              <label>件名</label>
              <input
                class="form-input w-100 mb-4"
                type="text"
                v-model="mail_subject8"
              />
              <label>本文</label>
              <textarea
                class="form-control"
                v-model="text8"
                rows="6"
              ></textarea>
            </div>
            <div class="col-sm-10 w-100" v-if="valueTypeMail === 9">
              <label>メールの送信者名</label>
              <input
                class="form-input w-100 mb-4"
                type="text"
                v-model="email_name9"
              />
              <label>件名</label>
              <input
                class="form-input w-100 mb-4"
                type="text"
                v-model="mail_subject9"
              />
              <label>本文</label>
              <textarea
                class="form-control"
                v-model="text9"
                rows="6"
              ></textarea>
            </div> -->
            <div class="col-sm-12 w-100 cp-4" v-if="valueTypeMail === Constants.AUTHOR_EMAIL_TYPE.external_payment_approval_check_to_user">
              <div
                class="row mx-0 align-items-center justify-content-center row-input mb-3"
              >
                <label class="col-2">メールの送信者名</label>
                <input
                  class="form-input w-100 mb-4 col-10"
                  type="text"
                  v-model="external_payment_approval_check_to_user_email_name"
                />
              </div>
              <div
                class="row mx-0 align-items-center justify-content-center row-input mb-3"
              >
                <label class="col-2">件名</label>
                <input
                  class="form-input w-100 mb-4 col-10"
                  type="text"
                  v-model="external_payment_approval_check_to_user_mail_subject"
                />
              </div>
              <div class="row mx-0 justify-content-center row-input mb-3">
                <label class="col-2">本文</label>
                <textarea
                  class="form-control col-10"
                  v-model="external_payment_approval_check_to_user_text"
                  rows="6"
                ></textarea>
              </div>
            </div>
            <div class="col-sm-12 w-100 cp-4" v-if="valueTypeMail === Constants.AUTHOR_EMAIL_TYPE.external_payment_approved_to_user">
              <div
                class="row mx-0 align-items-center justify-content-center row-input mb-3"
              >
                <label class="col-2">メールの送信者名</label>
                <input
                  class="form-input w-100 mb-4 col-10"
                  type="text"
                  v-model="external_payment_approved_to_user_email_name"
                />
              </div>
              <div
                class="row mx-0 align-items-center justify-content-center row-input mb-3"
              >
                <label class="col-2">件名</label>
                <input
                  class="form-input w-100 mb-4 col-10"
                  type="text"
                  v-model="external_payment_approved_to_user_mail_subject"
                />
              </div>
              <div class="row mx-0 justify-content-center row-input mb-3">
                <label class="col-2">本文</label>
                <textarea
                  class="form-control col-10"
                  v-model="external_payment_approved_to_user_text"
                  rows="6"
                ></textarea>
              </div>
            </div>
          </div>
          <!-- </div> -->
        </div>
      </form>
      <div class="d-flex justify-content-center p-4">
        <b-button
          v-on:click.prevent="CreateNewAuthor()"
          :disabled="isLoading"
          class="mr-4 btn-color"
          variant="success"
        >
          <span v-show="!isLoading"> 登録・更新する</span>
          <div
            v-show="isLoading"
            class="spinner-border spinner-border-sm"
            role="status"
          >
            <span class="sr-only">ローディング...</span>
          </div>
        </b-button>
        <b-button
          variant="secondary"
          class="mr-4 back-btn"
          v-on:click="returnList()"
          >戻る</b-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
// import ImageUploader from "vue-image-upload-resize";
import { VueEditor } from "vue2-editor";
import { Urls } from "../../utils/urls.js";
import { Api } from "../../utils/http-common.js";
import { Constants } from "../../utils/constants.js";
export default {
  name: "CreateEditAuthor",
  components: {
    // ImageUploader,
    VueEditor,
  },
  data() {
    return {
      Constants: Constants,
      name: "",
      author_id: "",
      email: "",
      author_profile: "",
      hasImage: false,
      image: null,
      isLoading: false,
      name_admin: "",
      id_admin: "",
      optionRankEditor: [
        { value: 0, text: "編集者（ランク1）" },
        { value: 1, text: "編集者（ランク2）" },
        { value: 2, text: "編集者（ランク3）" },
      ],
      rank_editor: 0,
      author_use: 1,
      optionAuthorUse: [
        { value: 0, text: "なし" },
        { value: 1, text: "あり" },
      ],
      last_name: "",
      first_name: "",
      id_author: "",
      semi_title: "",
      stripe_id: "",
      listLabelSettingMail: [
        { value: 0, text: "購入通知" },
        { value: 1, text: "購入自動返信" },
        { value: 2, text: "カード決済後" },
        { value: 3, text: "銀行申込後" },
        { value: 4, text: "振込確認後" },
        { value: 5, text: "定期購読申込後" },
        // { value: 6, text: "お客様に送るアンケート回答後メール" },
        // { value: 7, text: "お客様に送る投票後メール" },
        // { value: 8, text: "販売者に届く投票後通知" },
        // { value: 9, text: "お客様に送る申込（0円決済）後メール" },
        {
          value: Constants.AUTHOR_EMAIL_TYPE['external_payment_approval_check_to_user'],
          text: "外部決済手動承認受付",
        },
        {
          value: Constants.AUTHOR_EMAIL_TYPE['external_payment_approved_to_user'],
          text: "外部決済確認完了",
        },
      ],
      valueTypeMail: 0,
      email_name: "",
      subject: "",
      text: "",
      stripe_secret: "",
      paypal_id: "",
      paypal_secret: "",
      isChecked: 0,
      avatar: "",
      mail_subject: "",
      // shop_id: this.$route.params.shopId,
      shop_id: localStorage.getItem(Constants.SHOP_ID),
      id: this.$route.params.id,
      dataAuthor: {
        id: "",
        shop_id: "",
      },
      imagePre: null,
      managementofsalessite: "",
      privacy_policy: "",
      specified_URL: "",
      email_name1: "",
      mail_subject1: "",
      text1: "",
      email_name2: "",
      mail_subject2: "",
      text2: "",
      email_name3: "",
      mail_subject3: "",
      text3: "",
      email_name4: "",
      mail_subject4: "",
      text4: "",
      email_name5: "",
      mail_subject5: "",
      text5: "",
      email_name6: "",
      mail_subject6: "",
      text6: "",
      email_name7: "",
      mail_subject7: "",
      text7: "",
      email_name8: "",
      mail_subject8: "",
      text8: "",
      email_name9: "",
      mail_subject9: "",
      text9: "",
      // 外部決済手動承認受付
      external_payment_approval_check_to_user_email_name: "",
      external_payment_approval_check_to_user_mail_subject: "",
      external_payment_approval_check_to_user_text: "",
      // 外部決済確認完了
      external_payment_approved_to_user_email_name: "",
      external_payment_approved_to_user_mail_subject: "",
      external_payment_approved_to_user_text: "",
      checkImg: false,
      listSetting: [
        { value: 1, text: "基本設定" },
        { value: 2, text: "著者設定" },
        { value: 3, text: "詳細設定" },
        { value: 4, text: "メール設定" },
      ],
      typeSetting: 1,
      condition_display: 0,
      constant_display: Constants.CONDITION_DISPLAY_CONTENT,
      url_redirect: "",
      url_cancel: "",
      listContentOptions: [],
      listSlagOptions: [],
      slag_id_1: [],
      slag_id_2: [],
      slag_id_3: [],
      slag_id_4: [],
      content_id_1: [],
      content_id_2: [],
      content_id_3: [],
      content_id_4: [],
      admin_info: JSON.parse(
        localStorage.getItem(
          Constants.ADMIN_USER_INFO +
            (this.$route.params.shopId
              ? "_" + Constants.DOMAIN + "_" + this.$route.params.shopId
              : "_" + Constants.DOMAIN)
        )
      ),
      extConstant: this.$route.params.shopId
        ? "_" + Constants.DOMAIN + "_" + this.$route.params.shopId
        : "_" + Constants.DOMAIN,
      isCdeaAdmin: false,
      distributor_name: "",
      number_resgiter: "",
    };
  },
  created() {
    this.shop_id =
      localStorage.getItem(Constants.ADMIN_USER_INFO + this.extConstant) &&
      JSON.parse(
        localStorage.getItem(Constants.ADMIN_USER_INFO + this.extConstant)
      ).shop_id;
    const request = {
      shop_id: this.shop_id,
    };
    this.getALLlistSlag(request);
    const dataRequest = {
      page: 1,
      data: {
        shop_id: this.shop_id,
        limit: "all",
      },
    };
    this.getListContents(dataRequest);
    const extConstant = this.$route.params.shopId
      ? "_" + Constants.DOMAIN + "_" + this.$route.params.shopId
      : "_" + Constants.DOMAIN;
    const userType = localStorage.getItem(
      Constants.USER_TYPE_ADMIN + extConstant
    );
    if (
      userType == Constants.USER_TYPE_DEFAULT.cdea ||
      userType == Constants.USER_TYPE_DEFAULT.owner
    )
      this.isCdeaAdmin = true;
    const { params } = this.$route;
    this.dataAuthor.id = params.id;
    this.dataAuthor.shop_id = this.shop_id;
    if (this.dataAuthor.id !== undefined) {
      this.getAuthorById(this.dataAuthor);
    }
    this.$store.commit("set", ["success", false]);
    this.$store.commit("set", ["message", ""]);
    this.$store.commit("set", ["error", false]);
  },
  computed: {
    ...mapGetters([
      "authorById",
      "success",
      "message",
      "error",
      "listSlag",
      "listContents",
    ]),
  },
  watch: {
    authorById() {
      this.imagePre = this.authorById.avatar
        ? Constants.URL_BE + "/" + this.authorById.avatar
        : "";
      this.mail_subject = this.authorById.mail_subject;
      this.name_sender = this.authorById.name_sender;
      this.mail_type = this.authorById.mail_type;
      this.stripe_secret = this.authorById.stripe_key;
      this.stripe_id = this.authorById.stripe_id;
      this.paypal_secret = this.authorById.paypal_key;
      this.paypal_id = this.authorById.paypal_id;
      this.author_profile = this.authorById.description;
      this.name_admin = this.authorById.admin_name;
      this.semi_title = this.authorById.semi_title;
      this.id_author = this.authorById.author_id;
      this.first_name = this.authorById.first_name;
      this.last_name = this.authorById.last_name;
      this.author_use = this.authorById.use_author;
      this.rank_editor = this.authorById.admin_rank;
      this.email = this.authorById.email;
      this.id_admin = this.authorById.author_id;
      this.text = this.authorById.text;
      this.email_name = this.authorById.name_sender;
      this.privacy_policy = this.authorById.policy;
      this.managementofsalessite = this.authorById.terms;
      this.specified_URL = this.authorById.url_trade_law;
      this.distributor_name = this.authorById.distributor_name;
      this.number_resgiter = this.authorById.number_resgiter;
      this.authorById.mails.forEach((element) => {
        if (element.mail_type === 0) {
          this.mail_subject = element.mail_subject;
          this.email_name = element.name_sender;
          this.text = element.text;
        } else if (element.mail_type === 1) {
          this.mail_subject1 = element.mail_subject;
          this.email_name1 = element.name_sender;
          this.text1 = element.text;
        } else if (element.mail_type === 2) {
          this.mail_subject2 = element.mail_subject;
          this.email_name2 = element.name_sender;
          this.text2 = element.text;
        } else if (element.mail_type === 3) {
          this.mail_subject3 = element.mail_subject;
          this.email_name3 = element.name_sender;
          this.text3 = element.text;
        } else if (element.mail_type === 4) {
          this.mail_subject4 = element.mail_subject;
          this.email_name4 = element.name_sender;
          this.text4 = element.text;
        } else if (element.mail_type === 5) {
          this.mail_subject5 = element.mail_subject;
          this.email_name5 = element.name_sender;
          this.text5 = element.text;
        } else if (element.mail_type === 6) {
          this.mail_subject6 = element.mail_subject;
          this.email_name6 = element.name_sender;
          this.text6 = element.text;
        } else if (element.mail_type === 7) {
          this.mail_subject7 = element.mail_subject;
          this.email_name7 = element.name_sender;
          this.text7 = element.text;
        } else if (element.mail_type === 8) {
          this.mail_subject8 = element.mail_subject;
          this.email_name8 = element.name_sender;
          this.text8 = element.text;
        } else if (element.mail_type === 9) {
          this.mail_subject9 = element.mail_subject;
          this.email_name9 = element.name_sender;
          this.text9 = element.text;
        }
        // 外部決済手動承認受付
        else if (element.mail_type === Constants.AUTHOR_EMAIL_TYPE.external_payment_approval_check_to_user) {
          this.external_payment_approval_check_to_user_email_name = element.name_sender;
          this.external_payment_approval_check_to_user_mail_subject = element.mail_subject;
          this.external_payment_approval_check_to_user_text = element.text;
        }
        // 外部決済承認完了
        else if (element.mail_type === Constants.AUTHOR_EMAIL_TYPE.external_payment_approved_to_user) {
          this.external_payment_approved_to_user_email_name = element.name_sender;
          this.external_payment_approved_to_user_mail_subject = element.mail_subject;
          this.external_payment_approved_to_user_text = element.text;
        }
      });
      this.condition_display = this.authorById.condition_display;
      this.url_redirect = this.authorById.url_redirect;
      this.url_cancel = this.authorById.url_cancel;
      if (
        this.condition_display ==
        Constants.CONDITION_DISPLAY_CONTENT["SET_CONTENT"]
      ) {
        if (
          this.authorById.content_id_1 &&
          this.authorById.content_id_1.length > 0
        ) {
          this.content_id_1 = this.authorById.content_id_1.map((item) => {
            let id = parseInt(item);
            let foundItem = this.listContents.find((item) => item.id === id);
            return {
              value: foundItem.id,
              title: foundItem.title,
            };
          });
        }
        if (
          this.authorById.content_id_2 &&
          this.authorById.content_id_2.length > 0
        ) {
          this.content_id_2 = this.authorById.content_id_2.map((item) => {
            let id = parseInt(item);
            let foundItem = this.listContents.find((item) => item.id === id);
            return {
              value: foundItem.id,
              title: foundItem.title,
            };
          });
        }
        if (
          this.authorById.content_id_3 &&
          this.authorById.content_id_3.length > 0
        ) {
          this.content_id_3 = this.authorById.content_id_3.map((item) => {
            let id = parseInt(item);
            let foundItem = this.listContents.find((item) => item.id === id);
            return {
              value: foundItem.id,
              title: foundItem.title,
            };
          });
        }
        if (
          this.authorById.content_id_4 &&
          this.authorById.content_id_4.length > 0
        ) {
          this.content_id_4 = this.authorById.content_id_4.map((item) => {
            let id = parseInt(item);
            let foundItem = this.listContents.find((item) => item.id === id);
            return {
              value: foundItem.id,
              title: foundItem.title,
            };
          });
        }
      } else if (
        this.condition_display ==
        Constants.CONDITION_DISPLAY_CONTENT["SET_SLAG"]
      ) {
        if (this.authorById.slag_id_1 && this.authorById.slag_id_1.length > 0) {
          this.slag_id_1 = this.authorById.slag_id_1.map((item) => {
            let id = parseInt(item);
            let foundItem = this.listSlag.find((item) => item.id === id);
            return {
              slag_id: foundItem.id,
              slag_name: foundItem.slag_name
                ? foundItem.slag_name
                : foundItem.slag_name_en,
            };
          });
        }
        if (this.authorById.slag_id_2 && this.authorById.slag_id_2.length > 0) {
          this.slag_id_2 = this.authorById.slag_id_2.map((item) => {
            let id = parseInt(item);
            let foundItem = this.listSlag.find((item) => item.id === id);
            return {
              slag_id: foundItem.id,
              slag_name: foundItem.slag_name
                ? foundItem.slag_name
                : foundItem.slag_name_en,
            };
          });
        }
        if (this.authorById.slag_id_3 && this.authorById.slag_id_3.length > 0) {
          this.slag_id_3 = this.authorById.slag_id_3.map((item) => {
            let id = parseInt(item);
            let foundItem = this.listSlag.find((item) => item.id === id);
            return {
              slag_id: foundItem.id,
              slag_name: foundItem.slag_name
                ? foundItem.slag_name
                : foundItem.slag_name_en,
            };
          });
        }
        if (this.authorById.slag_id_4 && this.authorById.slag_id_4.length > 0) {
          this.slag_id_4 = this.authorById.slag_id_4.map((item) => {
            let id = parseInt(item);
            let foundItem = this.listSlag.find((item) => item.id === id);
            return {
              slag_id: foundItem.id,
              slag_name: foundItem.slag_name
                ? foundItem.slag_name
                : foundItem.slag_name_en,
            };
          });
        }
      }
    },
    listSlag() {
      this.listSlagOptions = this.listSlag.map((item) => ({
        slag_id: item.id,
        slag_name: item.slag_name ? item.slag_name : item.slag_name_en,
      }));
    },
    listContents() {
      let listContents = [];
      listContents = this.listContents.map((item) => ({
        value: item.id,
        title: item.title,
      }));
      listContents.forEach((element) => {
        this.listContentOptions.push(element);
      });
      this.loadingContent = false;
    },
  },
  methods: {
    ...mapActions({ CreateAuthor: "CreateAuthor" }),
    ...mapActions({ getAuthorById: "getAuthorById" }),
    ...mapActions({ updateAuthorByID: "updateAuthorByID" }),
    ...mapActions({ getALLlistSlag: "getALLlistSlag" }),
    ...mapActions({ getListContents: "getListContents" }),
    async CreateNewAuthor() {
      this.isLoading = true;
      const shop_id = this.shop_id;
      const arrayMail = [
        {
          mail_type: 0,
          name_sender: this.email_name,
          mail_subject: this.mail_subject,
          text: this.text,
        },
        {
          mail_type: 1,
          name_sender: this.email_name1,
          mail_subject: this.mail_subject1,
          text: this.text1,
        },
        {
          mail_type: 2,
          name_sender: this.email_name2,
          mail_subject: this.mail_subject2,
          text: this.text2,
        },
        {
          mail_type: 3,
          name_sender: this.email_name3,
          mail_subject: this.mail_subject3,
          text: this.text3,
        },
        {
          mail_type: 4,
          name_sender: this.email_name4,
          mail_subject: this.mail_subject4,
          text: this.text4,
        },
        {
          mail_type: 5,
          name_sender: this.email_name5,
          mail_subject: this.mail_subject5,
          text: this.text5,
        },
        {
          mail_type: 6,
          name_sender: this.email_name6,
          mail_subject: this.mail_subject6,
          text: this.text6,
        },
        {
          mail_type: 7,
          name_sender: this.email_name7,
          mail_subject: this.mail_subject7,
          text: this.text7,
        },
        {
          mail_type: 8,
          name_sender: this.email_name8,
          mail_subject: this.mail_subject8,
          text: this.text8,
        },
        {
          mail_type: 9,
          name_sender: this.email_name9,
          mail_subject: this.mail_subject9,
          text: this.text9,
        },
        {
          mail_type: Constants.AUTHOR_EMAIL_TYPE.external_payment_approval_check_to_user,
          name_sender: this.external_payment_approval_check_to_user_email_name,
          mail_subject: this.external_payment_approval_check_to_user_mail_subject,
          text: this.external_payment_approval_check_to_user_text,
        },
        {
          mail_type: Constants.AUTHOR_EMAIL_TYPE.external_payment_approved_to_user,
          name_sender: this.external_payment_approved_to_user_email_name,
          mail_subject: this.external_payment_approved_to_user_mail_subject,
          text: this.external_payment_approved_to_user_text,
        },
      ];
      var formData = new FormData();
      formData.append("shop_id", shop_id);
      if (!this.imagePre) {
        formData.append("clear_avatar", true);
      } else {
        formData.append("avatar", this.avatar);
      }
      if (arrayMail && arrayMail.length > 0) {
        arrayMail.forEach((item, index) => {
          // formData.append("mails[" + index + "]", JSON.stringify(item));
          if (item.mail_subject && item.name_sender && item.text) {
            for (let previewKey in item) {
              formData.append(
                `mails[${index}][${previewKey}]`,
                item[previewKey]
              );
            }
          }
        });
      }
      formData.append(
        "stripe_key",
        this.stripe_secret ? this.stripe_secret.trim() : ""
      );
      formData.append("stripe_id", this.stripe_id ? this.stripe_id.trim() : "");
      formData.append(
        "paypal_key",
        this.paypal_secret ? this.paypal_secret.trim() : ""
      );
      formData.append("paypal_id", this.paypal_id ? this.paypal_id.trim() : "");
      formData.append(
        "description",
        this.author_profile ? this.author_profile.trim() : ""
      );
      formData.append(
        "admin_name",
        this.name_admin ? this.name_admin.trim() : ""
      );
      formData.append(
        "semi_title",
        this.semi_title ? this.semi_title.trim() : ""
      );
      formData.append("last_name", this.last_name ? this.last_name.trim() : "");
      formData.append(
        "first_name",
        this.first_name ? this.first_name.trim() : ""
      );
      formData.append(
        "policy",
        this.privacy_policy ? this.privacy_policy.trim() : ""
      );
      formData.append(
        "terms",
        this.managementofsalessite ? this.managementofsalessite.trim() : ""
      );
      formData.append("use_author", this.author_use);
      formData.append("admin_rank", this.rank_editor);
      formData.append("email", this.email ? this.email.trim() : "");
      if (this.$route.params.id) formData.append("id", this.$route.params.id);
      formData.append(
        "url_trade_law",
        this.specified_URL ? this.specified_URL.trim() : ""
      );
      formData.append("condition_display", this.condition_display);
      if (this.condition_display == this.constant_display["SET_CONTENT"]) {
        if (this.content_id_1 && this.content_id_1.length > 0) {
          this.content_id_1.forEach((value, index) => {
            formData.append("content_id_1[" + index + "]", value.value);
          });
        } else formData.append("content_id_1", []);
        if (
          this.content_id_1.length > 0 &&
          this.content_id_2 &&
          this.content_id_2.length > 0
        ) {
          this.content_id_2.forEach((value, index) => {
            formData.append("content_id_2[" + index + "]", value.value);
          });
        } else formData.append("content_id_2", []);
        if (
          this.content_id_1.length > 0 &&
          this.content_id_2.length > 0 &&
          this.content_id_3 &&
          this.content_id_3.length > 0
        ) {
          this.content_id_3.forEach((value, index) => {
            formData.append("content_id_3[" + index + "]", value.value);
          });
        } else formData.append("content_id_3", []);
        if (this.content_id_4 && this.content_id_4.length > 0) {
          this.content_id_4.forEach((value, index) => {
            formData.append("content_id_4[" + index + "]", value.value);
          });
        } else formData.append("content_id_4", []);
      } else if (this.condition_display == this.constant_display["SET_SLAG"]) {
        if (this.slag_id_1 && this.slag_id_1.length > 0) {
          this.slag_id_1.forEach((value, index) => {
            formData.append("slag_id_1[" + index + "]", value.slag_id);
          });
        } else formData.append("slag_id_1", []);
        if (
          this.slag_id_1.length > 0 &&
          this.slag_id_2 &&
          this.slag_id_2.length > 0
        ) {
          this.slag_id_2.forEach((value, index) => {
            formData.append("slag_id_2[" + index + "]", value.slag_id);
          });
        } else formData.append("slag_id_2", []);
        if (
          this.slag_id_1.length > 0 &&
          this.slag_id_2.length > 0 &&
          this.slag_id_3 &&
          this.slag_id_3.length > 0
        ) {
          this.slag_id_3.forEach((value, index) => {
            formData.append("slag_id_3[" + index + "]", value.slag_id);
          });
        } else formData.append("slag_id_3", []);
        if (this.slag_id_4 && this.slag_id_4.length > 0) {
          this.slag_id_4.forEach((value, index) => {
            formData.append("slag_id_4[" + index + "]", value.slag_id);
          });
        } else formData.append("slag_id_4", []);
      } else {
        formData.append("slag_id_1", []);
        formData.append("slag_id_2", []);
        formData.append("slag_id_3", []);
        formData.append("slag_id_4", []);
        formData.append("content_id_1", []);
        formData.append("content_id_2", []);
        formData.append("content_id_3", []);
        formData.append("content_id_4", []);
      }
      formData.append(
        "url_redirect",
        this.url_redirect ? this.url_redirect : ""
      );
      formData.append("url_cancel", this.url_cancel ? this.url_cancel : "");
      formData.append(
        "distributor_name",
        this.distributor_name ? this.distributor_name : ""
      );
      formData.append("number_resgiter", this.number_resgiter);
      if (this.dataAuthor.id === undefined) {
        const dataReturn = await this.$store.dispatch("CreateAuthor", formData);
        this.isLoading = false;
        if (dataReturn.success === true) {
          this.$toasted.success(dataReturn.message);
          this.$router.go(-1);
        } else {
          this.$toasted.error(dataReturn.message);
        }
      } else {
        const dataReturn = await this.$store.dispatch(
          "updateAuthorByID",
          formData
        );
        this.isLoading = false;
        if (dataReturn.success === true) {
          this.$toasted.success(dataReturn.message);
          this.$router.go(-1);
        } else {
          this.$toasted.error(dataReturn.message);
        }
      }
    },
    returnList() {
      setTimeout(() => {
        this.$router.go(-1);
      }, 1000);
    },
    setValueLabel(value) {
      this.valueTypeMail = value;
    },
    setImage: function (output) {
      this.hasImage = true;
      this.filename = output.name;
      this.avatar = output;
      this.imagePre = null;
    },
    ClickContentChecked(index) {
      if (this.isChecked === index) {
        this.isChecked = null;
      } else {
        this.isChecked = index;
        this.isActive = null;
      }
    },
    async handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      if (file.size / 1024 / 1024 > 100) {
        this.$toasted.error("画像1枚につき100MBまで");
        resetUploader();
      } else {
        var formData = new FormData();
        formData.append("image", file);
        formData.append("id", this.shop_id);

        const url = `${Urls.PAGE}/${Urls.UPLOAD}`;
        const method = "POST";
        await Api.adminRequestServer({
          method,
          url,
          data: formData,
        })
          .then((response) => {
            const url = response.data.data; // Get url from response
            Editor.insertEmbed(cursorLocation, "image", url);
            resetUploader();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    handleImageRemove(file) {
      const url = `${Urls.PAGE}/${Urls.UPLOAD}`;
      const method = "POST";
      Api.adminRequestServer({
        method,
        url,
        data: file,
      });
    },
    async changePDF(e) {
      var formData = new FormData();
      formData.append("image", e.target.files[0]);
      formData.append("id", this.shop_id);
      const url = `${Urls.PAGE}/${Urls.UPLOAD}`;
      const method = "POST";
      await Api.adminRequestServer({
        method,
        url,
        data: formData,
      })
        .then((response) => {
          const url = response.data.data; // Get url from response
          this.chapter_content +=
            '<iframe class="ql-pdf" frameborder="0" allowfullscreen="true" src="' +
            url +
            '"></iframe><p><br></p>';
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onChangeimage(e) {
      if (
        e.target.files[0].type === "image/jpeg" ||
        e.target.files[0].type === "image/png" ||
        e.target.files[0].type === "image/jpg"
      ) {
        this.avatar = e.target.files[0];
        this.imagePre = URL.createObjectURL(this.avatar);
      } else {
        this.$toasted.error("画像がjpg、jpeg、png形式である必要があります。");
      }
    },
    deleteImage() {
      const url = `${Urls.PAGE}/${Urls.UPLOAD}`;
      const method = "POST";
      Api.adminRequestServer({
        method,
        url,
        data: this.imagePre,
      });
      this.imagePre = null;
      this.avatar = null;
      this.is_delete_logo = 1;
      document.getElementById("fileInput").value = "";
    },
  },
};
</script>
<style lang="scss" scoped>
.create-form {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  padding: 0px !important;
}
.ql-editor {
  min-height: 200px;
  font-size: 16px;
  background-color: white;
}
.preview-img-author {
  min-width: 100%;
  max-width: 300px;
  max-height: 300px;
  object-fit: contain;
  overflow: hidden;
  border-radius: 3px;
}
img {
  max-height: 300px;
  max-width: 300px;
}
.textMust {
  color: #fff;
  background: red;
  padding: 2px 0px;
  border-radius: 5px;
  top: 0px;
  margin-left: 15px;
  font-size: 11px;
}
.custom-text {
  background-color: #f2f2f2 !important;
  height: 40px;
  align-items: center;
  display: flex;
}
.row-input input {
  margin: 0px !important;
}
.btn-tab-sale-active {
  background: #ff644e;
  border: none;
  color: #fff;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 0.75rem 1.5rem;
  font-size: 20px;
  line-height: 1.5;
  border-radius: 3px;
  width: 160px;
}
.btn-tab-sale-inactive {
  color: #636f83;
  border-color: #636f83;
  display: inline-block;
  font-weight: 400;
  color: #3c4b64;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid #636f83;
  padding: 0.75rem 1.5rem;
  font-size: 20px;
  line-height: 1.5;
  border-radius: 3px;
  width: 160px;
}
.btn-tab-sale-active:after {
  position: absolute;
  bottom: -10px;
  left: 50%;
  margin-left: -10px;
  content: "";
  display: block;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #ff644e;
}
@media (min-width: 1280px) {
  .div-preview-btn {
    position: absolute;
    right: 0;
  }
}
@media (max-width: 1280px) {
  .div-preview-btn {
    position: relative;
    margin-left: 40px;
    margin-bottom: 15px;
  }
}
@media (max-width: 1024px) {
  .btn-tab-sale-inactive {
    padding: 0.75rem 1rem;
    font-size: 18px;
    width: 110px;
  }
  .btn-tab-sale-active {
    padding: 0.75rem 1rem;
    font-size: 18px;
    width: 110px;
  }
}
.btn-tab-mail-active {
  background: #ff644e !important;
  border: #ff644e !important;
  color: #fff !important;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  padding: 7px;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 3px;
  min-width: 100px;
}
.btn-tab-mail-inactive {
  color: #636f83;
  border-color: #636f83;
  display: inline-block;
  font-weight: 400;
  color: #3c4b64;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid #636f83;
  padding: 7px;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 3px;
  min-width: 100px;
}
.btn-tab-mail-active:after {
  position: absolute;
  bottom: -10px;
  left: 50%;
  margin-left: -10px;
  content: "";
  display: block;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #ff644e;
}
.sale-btn {
  width: 120px !important;
  height: 42px !important;
  background-color: #00b797 !important;
  color: #fff !important;
  border-radius: 6px !important;
  font-size: 20px;
}
.sale-btn:hover {
  width: 120px !important;
  height: 42px !important;
  background-color: #00ab82 !important;
  color: #fff !important;
  border-radius: 6px !important;
}
.btn-toggle-active {
  display: inline-block;
  position: relative;
  .btn-checkbox-active {
    border: 0;
  }
  input[type="radio"] {
    opacity: 0;
    position: absolute;
    z-index: 1;
    width: 100%;
    inset: 0;
    &:checked ~ .btn-checkbox-active {
      background: #ff644e;
      color: #fff;
    }
  }
}
.btn-toggle-active {
  margin-right: 10px;
  &:last-child {
    margin-right: 0;
  }
}
</style>
